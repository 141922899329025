<template lang="pug">
  div
    OFFEOSunsettingModal(
      :is-show="isShowSunsettingModal"
      :hide-close="(isLoggedIn && isFreeUser) || isShowSubscriptionModal"
      @close="isShowSunsettingModal = false"
    )
    router-view
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
// eslint-disable-next-line import/extensions
import OFFEOSunsettingModal from '@/components/base/OFFEOSunsettingModal';

export default {
  components: {
    OFFEOSunsettingModal,
  },
  data() {
    return {
      isShowSunsettingModal: false,
    };
  },
  computed: {
    ...mapState([
      'isLoggedIn',
      'isShowSubscriptionModal',
    ]),
    ...mapGetters('userData', ['isFreeUser']),
  },
  mounted() {
    this.isShowSunsettingModal = true;
  },
};
</script>
