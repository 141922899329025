<template lang="pug">
  CanvasModal.base-sunsetting-modal(
    :class='{ "hide-close": isFreeUser || hideClose }',
    :is-show='isShow',
    @close='closeModal'
  )
    .sunsetting-announcement
      h1.sunsetting-subject
        | Important: OFFEO is Shutting Down on&nbsp;
        strong April 23, 2025
      p.sunsetting-greeting.text--bold Dear OFFEO User,
      p.sunsetting-body
        | Thank you for being a valued part of&nbsp;
        strong OFFEO
        | . After careful consideration, we have made the difficult decision to&nbsp;
        strong shut down the platform on April 23, 2025, at 5:00 PM (GMT+8)
        | .

      h2 What You Need to Know
      ul.sunsetting-list
        li.sunsetting-list-item
          strong Final Day to Access Your Account: April 23, 2025
        li.sunsetting-list-item
          strong Action Required:&nbsp;
          | Please&nbsp;
          strong download all your videos&nbsp;
          | before this date, as access to your account will no longer be available after the shutdown.
        li.sunsetting-list-item
          | All subscriptions from March 20, 2025, to April 23, 2025, will be refunded

      p.sunsetting-body
        | If you have any questions, our support team is here to assist you at&nbsp;
        a.contact-email.text--bold(href='mailto:help@offeo.com') help@offeo.com
        | .

      p.sunsetting-body
        | We truly appreciate your support and the time you have spent with us.
        | Thank you for being part of our journey.

      p.sunsetting-closing.text--bold
        span Best regards
        br
        br
        span.team The OFFEO Team
  </template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BaseSunsettingModal',
  props: {
    isShow: { type: Boolean, default: false },
    hideClose: { type: Boolean, default: false },
  },
  head() {
    return {
      bodyAttrs: {
        class: this.isShow ? 'no-scroll' : '',
      },
    };
  },
  computed: {
    ...mapGetters('accountSettings', ['isFreeUser']),
  },
  methods: {
    closeModal() {
      // @dev: Dont allow free user to close the modal
      if (this.isFreeUser || this.hideClose) return;

      this.$emit('close');
      document.body.closest('html').classList.remove('no-scroll');
    },
  },
};
</script>

<style lang="scss">
.base-sunsetting-modal {
  &.hide-close .canvas-modal__closebtn {
    display: none !important;
  }

  .canvas-modal__content {
    max-width: 800px !important;
    background: var(--lightgrey100-black800) !important;
    line-height: 1.4;
    text-align: left;
    font-weight: 400 !important;
    font-family: $baseFont;
    border-radius: 20px;

    .canvas-modal__closebtn {
      color: var(--black800-lightgrey100) !important;
    }

    .content {
      color: var(--black-white) !important;
      padding: 40px;
    }

    h1,
    h2,
    p {
      margin: 0;
      padding: 0;
    }

    .text--bold,
    strong {
      font-weight: 700;
      color: inherit !important;
    }

    h2,
    p {
      font-size: 1rem;
    }
  }

  .sunsetting-announcement {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .sunsetting-subject {
    font-size: 1.25rem;
    font-weight: 400;
    margin: 0;
  }

  .sunsetting-list {
    list-style: disc !important;
    padding: 0 0 0 40px !important;
    margin: 0;
  }

  @include mobile() {
    .canvas-modal__content {
      .content {
        padding: 24px 16px;
      }

      h2,
      p {
        font-size: 0.875rem;
      }
    }

    .sunsetting-subject {
      font-size: 1.125rem;
    }

    .sunsetting-list {
      padding: 0 0 0 24px !important;
    }
  }
}
</style>
